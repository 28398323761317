import React, { useState } from 'react';
import { hostname } from '../../utils/constants';
import axios from 'axios';
import SiteLayout from '../../components/layout/SiteLayout';
import { Link } from 'gatsby';
import ReCaptcha from '@matt-block/react-recaptcha-v2';
import { Map } from '../../components/GoogleMaps';

if (typeof window !== 'undefined') {
  window.initMap = function() {
    new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 40, lng: 10 },
      zoom: 5,
    })
  }
}

const ContactUs = ({ data }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [messageError, setMessageError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loadCaptcha, setLoadCaptcha] = useState(false);

  let ldJsonHome = "Accueil";
  let ldJsonLevel1Route = "/contact/";
  let ldJsonLevel1Name = "Contact";

  let navigationLdJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": hostname + "/",
          "name": ldJsonHome
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": hostname + "/" + data?.mdx?.fields?.lang + ldJsonLevel1Route,
          "name": ldJsonLevel1Name
        }
      }
    ]
  };

  const errorMessages = {
    en: {
      notEmpty: "Ce champ est obligatoire.",
      email: "Veuillez saisir une adresse e-mail valide.",
      phoneNumber: "Veuillez saisir un numéro de téléphone valide.",
      captcha: "Veuillez valider le captcha avant de soumettre.",
      general: "Veuillez vous assurer que tous les champs sont valides.",
      api: "Une erreur s'est produite lors du traitement de votre demande. Veuillez vous assurer que tous les champs sont valides et réessayer."
    }
  };

  const handleChange = (field, event) => {
    if (field === 'name') {
      setName(event?.target?.value);
    } else if (field === 'email') {
      setEmail(event?.target?.value);
    } else if (field === 'phoneNumber') {
      setPhoneNumber(event?.target?.value);
    } else {
      setMessage(event?.target?.value);
    }

    setLoadCaptcha(true);
  }

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setErrorMessage(null);
    setShowSuccess(false);
    let toReturn = true;

    if (!emailRegex.test(email)) {
      setEmailError(errorMessages.en.email);
      setErrorMessage(errorMessages.en.general);
      toReturn = false;
    } else {
      setEmailError(null);
    }

    if (!(!phoneNumber || phoneNumber.match(/\d/g).length === 10)) {
      setPhoneError(errorMessages.en.phoneNumber);
      setErrorMessage(errorMessages.en.general);
      toReturn = false;
    } else {
      setPhoneError(null);
    }

    if (!name) {
      setNameError(errorMessages.en.notEmpty);
      setErrorMessage(errorMessages.en.general);
      toReturn = false;
    } else {
      setNameError(null);
    }

    if (!message) {
      setMessageError(errorMessages.en.notEmpty);
      setErrorMessage(errorMessages.en.general);
      toReturn = false;
    } else {
      setMessageError(null);
    }

    if (!captcha) {
      setErrorMessage(errorMessages.en.captcha);
      return false;
    }

    return toReturn;
  };

  const onSubmit = () => {
    setLoadCaptcha(true);
    if (validateForm()) {
      const data = {
        Name: name,
        Email: email,
        PhoneNumber: phoneNumber,
        Message: message,
        CaptchResponse: captcha
      };
      axios.post("https://app.elapseit.com/api/websitecontact/SubmitContact", data).then(r => {
        setShowSuccess(true);
      }, error => setErrorMessage(errorMessages.en.api));
    }
  }

  return (
    <SiteLayout relatedPages={data?.mdx?.fields?.relatedPages} lang={data?.mdx?.fields?.lang} canonical={data?.mdx?.fields?.canonical} title={"Contact"} seo={{
      metaTitle: data?.mdx?.frontmatter?.metaTitle,
      metaDescription: data?.mdx?.frontmatter?.metaDescription,
      metaImage: data?.mdx?.frontmatter?.metaImage?.publicURL,
      metaCover: data?.mdx?.frontmatter?.metaCover?.publicURL,
      twitterCard: data?.mdx?.frontmatter?.twitterCard,
      ldJson: data?.mdx?.frontmatter?.ldJson,
      navigationLdJson: navigationLdJson,
      keywords: data?.mdx?.frontmatter?.metaKeywords,
      slug: data?.mdx?.fields?.slug.replace("/posts/index", "")
    }}>
      <div className={"w-full flex flex-col features-bg-image px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96  "}>
        <div className={"w-full"}>
          <p className={"text-right w-full text-sm text-gray-500 mt-5"}><Link to={"/"}>Accueil</Link> > Contact</p>
        </div>
        <h1
          className={"text-xl md:text-2xl lg:text-4xl text-center text-primary-default font-light py-20"}>{data?.mdx?.frontmatter?.headerText}</h1>
      </div>
      <div className={"w-full flex flex-col md:flex-row px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96  "}>
        <div className={"w-full md:w-2/3 flex flex-col"}>
          <h2
            className={"text-xl md:text-xl lg:text-3xl text-center text-gray-600 font-light pb-10"}>{data?.mdx?.frontmatter?.contactHeader}</h2>
          <div className={"mb-5"}>
            {data?.mdx?.frontmatter?.contactDescription.map((description, index) => <p
              className={"lg:text-lg text-gray-500"} key={index}>{description}</p>)}
          </div>

          <div className={"w-full xl:w-2/3 flex flex-col contact-us-form md:mr-5 mt-5 md:mt-0 mb-10"}>
            <div className={"form-input"}>
              <i className={data?.mdx?.frontmatter?.nameIcon} />
              <input type={'text'} placeholder={data?.mdx?.frontmatter?.namePlaceholder} value={name}
                     onChange={(event) => handleChange('name', event)} />
              <span className={"text-sm text-red-600 ml-5"}>{nameError}</span>
            </div>
            <div className={"form-input"}>
              <i className={data?.mdx?.frontmatter?.emailIcon} />
              <input type={'email'} placeholder={data?.mdx?.frontmatter?.emailPlaceholder} value={email}
                     onChange={(event) => handleChange('email', event)} />
              <span className={"text-sm text-red-600 ml-5"}>{emailError}</span>
            </div>
            <div className={"form-input"}>
              <i className={data?.mdx?.frontmatter?.phoneNumberIcon} />
              <input type={'email'} placeholder={data?.mdx?.frontmatter?.phoneNumberPlaceholder} value={phoneNumber}
                     onChange={(event) => handleChange('phoneNumber', event)} />
              <span className={"text-sm text-red-600 ml-5"}>{phoneError}</span>
            </div>
            <div className={"form-input"}>
              <i className={data?.mdx?.frontmatter?.messageIcon} />
              <textarea placeholder={data?.mdx?.frontmatter?.messagePlaceholder} value={message}
                        onChange={(event) => handleChange('message', event)} />
              <span className={"text-sm text-red-600 ml-5"}>{messageError}</span>
            </div>

            {loadCaptcha && <ReCaptcha
              siteKey="6LcamqEcAAAAAD1jKWj-t8J-FvR5fFL2ggE_oQ-X"
              theme="light"
              size="normal"
              onSuccess={(captcha) => setCaptcha(captcha)}
              onExpire={() => setCaptcha(null)}
            />}

            <span className={"text-sm text-red-600 ml-5 mt-2"}>{errorMessage}</span>
            {showSuccess &&
              <span className={"text-sm text-primary-default ml-5 mt-2"}>Your message has been sent.</span>}

            <div onClick={onSubmit}
                 className={"mt-5 uppercase text-primary-default cursor-pointer border-2 rounded-2xl w-40 text-center border-primary-default px-5 py-2 hover:bg-primary-default hover:text-white font-bold"}>
              {data?.mdx?.frontmatter?.submitButtonText}
            </div>
          </div>
        </div>
        <div className={"w-full md:w-1/3 flex flex-col md:ml-10 mt-10 md:mt-0"}>
          <h2
            className={"text-xl md:text-xl lg:text-3xl text-gray-600 font-light pb-10 text-center md:text-left"}>{data?.mdx?.frontmatter?.phoneNumberHeader}</h2>
          <div className={"mb-5"}>
            {data?.mdx?.frontmatter?.phoneCountries.map((country, index) => <p className={"lg:text-lg text-gray-500"}
                                                                               key={index}>{country}: <a
              className={"font-light"} target={"_blank"}
              href={"tel:" + data?.mdx?.frontmatter?.phoneNumbers[index]}>{data?.mdx?.frontmatter?.phoneNumbers[index]}</a>
            </p>)}
          </div>

          <h2
            className={"text-xl md:text-xl lg:text-3xl text-gray-600 font-light pb-10 pt-10 text-center md:text-left"}>{data?.mdx?.frontmatter?.emailHeader}</h2>
          <div className={"mb-5"}>
            {data?.mdx?.frontmatter?.emailTexts.map((text, index) => <p className={"lg:text-lg text-gray-500"}
                                                                        key={index}>{text}: <a
              className={"font-light"} target={"_blank"}
              href={"mailto:" + data?.mdx?.frontmatter?.emails[index]}>{data?.mdx?.frontmatter?.emails[index]}</a>
            </p>)}
          </div>

          <h2
            className={"text-xl md:text-xl lg:text-3xl text-gray-600 font-light pb-10 pt-10 text-center md:text-left"}>{data?.mdx?.frontmatter?.headquarterHeader}</h2>
          <div className={"mb-5"}>
            {data?.mdx?.frontmatter?.headquarterLines.map((text, index) => <p
              className={"lg:text-lg text-gray-500"} key={index}>{text}</p>)}
          </div>
        </div>
      </div>
      <Map isMarkerShown
           googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB63U-QWGwqRbLUrpuAyuSxERbRQJP_Be0"
           loadingElement={<div style={{ height: `100%` }} />}
           containerElement={<div style={{ height: `400px` }} />}
           mapElement={<div style={{ height: `100%` }} />} />
    </SiteLayout>
  );
}

export default ContactUs;
